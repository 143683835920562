import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    // --------------------------------------------------
    // -------------------- New Data --------------------
    headerCollapsed: false,
    sectionMap: {},
    sectionMeta: {},
    sportsbook: null,
    recaptchaId: null,

    // The current active sections games/categories
    games: [],
    gameIdMap: {},
    gameSystemMap: {},

    // The total unique games/categories across all sections
    _games: [],
    _gameIdMap: {},
    _categories: {},
    _gameSystemMap: {},
    _homeGames: null,

    // --------------------------------------------------
    // --- "api/site/settings/all" API Response Items ---
    recaptchaSiteKey: '',
    slides: [],
    googleAnalyticsSiteId: '',
    suppliers: [],
    sliderSpeed: '',
    sliderOnMobile: true,
    defaultSection: '',
    siteCopyright: '',
    countries: [],
    recaptchaEnabled: false,
    sections: [],
    paymentMethods: [],
    sliderEnabled: false,
    indexPages: [],
    indexNews: [],
    currencies: [],
    // unused now, but present in response
    googleAnalyticsEnabled: false,

    // --- removed settings
    oauthEndpoints: {
        xumm: 'https://whitelabel.casinowarehouse1.com/oauth2-rnr/oauth2/authorization/xumm-idp'
    },
    // -------------------------------------------------------------
    // --- "api/site/section/${section-name}" API Response Items ---
    currentSection: '',
    metaTags: [],
    gamesListData: {}
};

const mainSlice = createSlice({
    name: 'main',
    initialState,
    reducers: {
        retrievedAllSettings: (state, action) => {
            const {
                sections,
                indexPages,
                defaultSection,
            } = action.payload;

            let i;
            const sectionMap = {};

            for (i = 0; i < sections.length; i++) {
                const section = sections[i];
                sectionMap[section.systemTag] = section;
                if (section.systemTag === defaultSection) {
                    sectionMap['defaultSection'] = section;
                }
            }

            const pageMap = {};
            for (i = 0; i < indexPages.length; i++) {
                pageMap[indexPages.alias] = indexPages[i];
            }

            return {
                ...state,
                ...action.payload,
                sectionMap,
                pageMap,
            };
        },
        setCurrentSection: (state, action) => ({
            ...state,
            currentSection: action.payload,
        }),
        // This method sets the headerCollapsed property
        setHeaderCollapsed: (state, action) => ({
            ...state,
            headerCollapsed: action.payload,
        }),
        // This method sets the recaptchaId
        setRecaptchaId: (state, action) => ({
            ...state,
            recaptchaId: action.payload,
        }),
    }
});

export const {
    retrievedAllSettings,
    setCurrentSection,
    setHeaderCollapsed,
    setRecaptchaId,
} = mainSlice.actions;

export default mainSlice.reducer;
